@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 100;
  src: local('Montserrat'), local('Montserrat-Thin'), url('./fonts/Montserrat-Light.woff2') format('woff2'),
    url('./fonts/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 100;
  src: local('Montserrat'), local('Montserrat-ThinItalic'), url('./fonts/Montserrat-LightItalic.woff2') format('woff2'),
    url('./fonts/Montserrat-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 200;
  src: local('Montserrat'), local('Montserrat-ExtraLight'), url('./fonts/Montserrat-Light.woff2') format('woff2'),
    url('./fonts/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 200;
  src: local('Montserrat'), local('Montserrat-ExtraLightItalic'),
    url('./fonts/Montserrat-LightItalic.woff2') format('woff2'),
    url('./fonts/Montserrat-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat'), local('Montserrat-Light'), url('./fonts/Montserrat-Light.woff2') format('woff2'),
    url('./fonts/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 300;
  src: local('Montserrat'), local('Montserrat-LightItalic'), url('./fonts/Montserrat-LightItalic.woff2') format('woff2'),
    url('./fonts/Montserrat-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat'), local('Montserrat-Book'), url('./fonts/Montserrat-Regular.woff2') format('woff2'),
    url('./fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat'), local('Montserrat-BookItalic'), url('./fonts/Montserrat-Italic.woff2') format('woff2'),
    url('./fonts/Montserrat-Italic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat'), local('Montserrat-Medium'), url('./fonts/Montserrat-Medium.woff2') format('woff2'),
    url('./fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 500;
  src: local('Montserrat'), local('Montserrat-MediumItalic'),
    url('./fonts/Montserrat-MediumItalic.woff2') format('woff2'),
    url('./fonts/Montserrat-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat'), local('Montserrat-SemiBold'), url('./fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('./fonts/Montserrat-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 600;
  src: local('Montserrat'), local('Montserrat-SemiBoldItalic'),
    url('./fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
    url('./fonts/Montserrat-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat'), local('Montserrat-Bold'), url('./fonts/Montserrat-Bold.woff2') format('woff2'),
    url('./fonts/Montserrat-Bold.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat'), local('Montserrat-BoldItalic'), url('./fonts/Montserrat-BoldItalic.woff2') format('woff2'),
    url('./fonts/Montserrat-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat'), local('Montserrat-ExtraBold'), url('./fonts/Montserrat-ExtraBold.woff2') format('woff2'),
    url('./fonts/Montserrat-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 800;
  src: local('Montserrat'), local('Montserrat-ExtraBoldItalic'),
    url('./fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/Montserrat-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 900;
  src: local('Montserrat'), local('Montserrat-Black'), url('./fonts/Montserrat-ExtraBold.woff2') format('woff2'),
    url('./fonts/Montserrat-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 900;
  src: local('Montserrat'), local('Montserrat-BlackItalic'),
    url('./fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/Montserrat-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'dripicons-v2';
  src: url('./fonts/dripicons/dripicons-v2.eot');
  src: url('./fonts/dripicons/dripicons-v2.eot?#iefix') format('embedded-opentype'),
    url('./fonts/dripicons/dripicons-v2.woff') format('woff'),
    url('./fonts/dripicons/dripicons-v2.ttf') format('truetype'),
    url('./fonts/dripicons/dripicons-v2.svg#dripicons-v2') format('svg');
  font-weight: normal;
  font-style: normal;
}


@import '../../../../shared/assets/style/theme.scss';

.gtp-modal-dailybonus {
  width: 100%;
  height: 100%;
  max-width: none;
  margin: 0;
  transform: none !important;
  transition: none !important;

  .modal-content {
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 0;

    .wheel {
      position: absolute;

      width: 90vh;
      height: 90vh;
      @include isInternetExplorer() {
        top: 30px;
        left: 50vh;
      }
    }
  }

  .modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .gradientText {
    @include linearGradientText($common-0, $common-1 20%, $common-32 100%);
    text-shadow: 0px 1px 2px $common-26;
  }

  .blackGradient {
    @include linearGradientText($common-0, rgb(16, 16, 16) 20%, rgb(54, 54, 54) 100%);
    color: $common-4;
    -webkit-text-fill-color: $common-4;
    text-shadow: 0px 1px 2px $common-26;
  }

  .greyGradient {
    @include linearGradientText($common-0, rgb(16, 16, 16) 20%, rgb(54, 54, 54) 100%);
    background: linear-gradient($common-7, $common-3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .spinText {
    font-size: 2.8rem;
    font-weight: 700;
  }

  .spinnerItemText {
    padding-left: 0.5rem;
    margin-right: 2vh;
    font-weight: 700;
  }

  .streakText {
    text-align: center;
    margin-left: 8;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .bonusContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 150px;
    line-height: 1.2;

    .bonusAmount {
      font-size: 1.6rem;
      font-weight: 500;
      text-align: center;
    }

    .bonusInfo {
      font-size: 0.9rem;
      font-weight: 400;
    }

    .tierNotLinked {
      font-size: 0.8rem;
      font-weight: 400;
      white-space: nowrap;
      @media only screen and (max-width: 1400px) {
        font-size: 0.7rem;
      }
    }

    &.WheelBonusAnimation {
      animation-duration: 1500ms;
      animation-name: wheelBonusZoom;
      transform: scale(1.1);

      @keyframes wheelBonusZoom {
        0% {
          transform: scale(0.8);
        }
        66% {
          transform: scale(0.8);
        }
        100% {
          transform: scale(1.1);
        }
      }
    }

    &.DailyBonusAnimation {
      animation-duration: 2000ms;
      animation-name: dailyBonusZoom;
      transform: scale(1.1);

      @keyframes dailyBonusZoom {
        0% {
          transform: scale(0.8);
        }
        75% {
          transform: scale(0.8);
        }
        100% {
          transform: scale(1.1);
        }
      }
    }

    &.TierBonusAnimation {
      animation-duration: 2500ms;
      animation-name: tierBonusZoom;
      transform: scale(1.1);

      @keyframes tierBonusZoom {
        0% {
          transform: scale(0.8);
        }
        80% {
          transform: scale(0.8);
        }
        100% {
          transform: scale(1.1);
        }
      }
    }

    &.BonusContainerSmall {
      transform: scale(0.8);
    }
  }
}

.TotalBonusAnimationNotVerified {
  animation-duration: 2500ms;
  animation-name: totalBonusZoom;
  transform: scale(1.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;

  @keyframes totalBonusZoom {
    0% {
      transform: scale(0.8);
    }
    80% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.1);
    }
  }
}

.TotalBonusAnimationVerified {
  animation-duration: 3000ms;
  animation-name: totalBonusZoom;
  transform: scale(1.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;

  @keyframes totalBonusZoom {
    0% {
      transform: scale(0.8);
    }
    83% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.1);
    }
  }
}

.TotalBonusSmall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
}

@include isInternetExplorer() {
  .WheelSliceContainer {
    top: -40px !important;
  }

  .DailyBonusStreakTitle {
    height: 25px !important;
    width: 156px !important;
    position: absolute;
    top: -30px;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .DayStreakContainer {
    justify-content: space-around;
  }

  .FinalDayAccent {
    width: 40px !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}

.gtp-modal-daily-spin-onboarding {
  max-width: 65%;
}

.gtp-modal-daily-spin-onboarding-small {
  max-width: 65%;
  margin-top: 5rem;
}

